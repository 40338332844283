<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="设备" prop="devId">
                <a-select v-model="queryParam.devId" placeholder="请选择" allow-clear>
                  <a-select-option v-for="(item,key) in devList" :key="key" :value="item.id">{{ item.valveMac }}（{{ item.valveName }}）</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="上报人">
                <a-input v-model="queryParam.userName" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="上报时间">
                <a-range-picker style="width: 100%" v-model="dateRange" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="项目" prop="projectId">
                <a-select v-model="queryParam.projectId" placeholder="请选择项目" allow-clear>
                  <a-select-option v-for="(item,key) in projectList" :key="key" :value="item.id">{{ item.projectName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- <a-col :md="8" :sm="24">
              <a-form-item label="类型" prop="type">
                <a-select v-model="queryParam.type" placeholder="请选择" allow-clear>
                  <a-select-option v-for="(item,key) in [{name:'操作数据',id:0},{name:'运行数据',id:1}]" :key="key" :value="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
            <a-col :md="8 || 24" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport()">导出</a-button>
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <list ref="list" @ok="getList"/>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="type" slot-scope="text, record">
          <a-tag color="orange" v-if="record.type == 0">操作数据</a-tag>
          <a-tag color="blue" v-else-if="record.type == 1">运行数据</a-tag>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.list.getInfo(record.id)">
            <a-icon type="eye" />详情
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listDevice } from '@/api/valve/device'
import { listProject } from '@/api/valve/project'
import { pageHistory } from '@/api/valve/history'
import list from './modules/list'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'History',
  components: {
    list
  },
  mixins: [tableMixin],
  data () {
    return {
      projectList: [],
      devList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 日期范围
      dateRange: [],
      // 查询参数
      queryParam: {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        devId: undefined,
        type: 1,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '设备名称',
          dataIndex: 'devName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备MAC地址',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '项目名称',
          dataIndex: 'projectName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '数据类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '上报用户',
          dataIndex: 'createUserName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '上报时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    listProject().then(res => {
      this.projectList = res.data
    })
    listDevice().then(res => {
      this.devList = res.data
    })
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询历史记录列表 */
    getList () {
      this.loading = true
      if (this.dateRange !== null && this.dateRange !== '' && this.dateRange.length === 2) {
        this.queryParam.createBeginTime = this.dateRange[0] + ' 00:00:00'
        this.queryParam.createEndTime = this.dateRange[1] + ' 23:59:59'
      } else {
        this.queryParam.createBeginTime = undefined
        this.queryParam.createEndTime = undefined
      }
     pageHistory(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.queryParam = {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        devId: undefined,
        type: 1,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('valve/valve-history/export', {
            ...that.queryParam
          }, `运行记录_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
